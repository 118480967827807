// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Insights-module__KtdsHdwy7I05HBUYhnVH div[class*=page-inner]{padding-left:0;padding-right:0;border:none;margin:0}.Insights-module__q1F36QewOtIueoxpBZHm{margin-top:16px}.Insights-module__LDoDTAekKJvwsxPOKuD9{margin-top:32px}", "",{"version":3,"sources":["webpack://./pages/insights/Insights.module.scss"],"names":[],"mappings":"AACA,8DACE,cAAA,CACA,eAAA,CACA,WAAA,CACA,QAAA,CAGF,uCACE,eAAA,CAGF,uCACE,eAAA","sourcesContent":["// Required to remove inner page padding since grafana-scenes doesn't support its style modification\n.insights div[class*='page-inner'] {\n  padding-left: 0;\n  padding-right: 0;\n  border: none;\n  margin: 0;\n}\n\n.spaceTop {\n  margin-top: 16px;\n}\n\n.alertBox {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insights": "Insights-module__KtdsHdwy7I05HBUYhnVH",
	"spaceTop": "Insights-module__q1F36QewOtIueoxpBZHm",
	"alertBox": "Insights-module__LDoDTAekKJvwsxPOKuD9"
};
export default ___CSS_LOADER_EXPORT___;
